'use client'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select"
import { useCustomImageContext } from "@/contexts/CustomImageContext"
import { useNonUrlContext } from "@/contexts/NonUrlContext"
import { useToast } from "@/hooks/use-toast"
import { cn } from "@/lib/utils"
import { TextItemType } from "@/types/contextTypes"
import { ColorType } from "@/utils/APIRouteTypes"
import { Atma_Font, Bahianita_Font, Bangers_Font, Barriecito_Font, Bebas_Font, Frijole_Font, Fuzzy_Font, Rubik_Font, Slackey_Font, Splash_Font, WetPaint_Font } from "@/utils/fonts"
import { PlusCircle, Settings, Trash2 } from 'lucide-react'
import React, { useDeferredValue, useEffect, useState } from 'react'
import { TextOnlyPreview } from "../imageViewer/textPreview"
import { Button } from '../ui/button'
import ButtonLink from "../ui/buttonLink"
import ColorRadio from "../ui/colorRadio"
import { Slider } from "../ui/slider"

import ColorPicker from "../ui/ColorPicker"
import { Textarea } from "../ui/textarea"

const colors = [
    { name: 'Black', value: '#000' },
    { name: 'White', value: '#f6f5f4' },
    { name: 'Purple', value: '#9547b8' },
    { name: 'Pink', value: '#ee11b2' },
    { name: 'Green', value: '#779e61' },
    { name: 'Yellow', value: '#d7c928' },
    { name: 'Orange', value: '#dc7923' },
    { name: 'Blue', value: '#0561fa' },
];

const Fonts: { name: string, fontClass: string }[] = [
    { name: 'Bebas', fontClass: Bebas_Font.className },
    { name: 'Bangers', fontClass: Bangers_Font.className },
    { name: 'Rubik', fontClass: Rubik_Font.className },
    { name: "Barriecito", fontClass: Barriecito_Font.className },
    { name: 'Atma', fontClass: Atma_Font.className },
    { name: "Bahianita", fontClass: Bahianita_Font.className },
    { name: "Fuzzy", fontClass: Fuzzy_Font.className },
    { name: "WetPaint", fontClass: WetPaint_Font.className },
    { name: "Frijole", fontClass: Frijole_Font.className },
    { name: "Slackey", fontClass: Slackey_Font.className },
]

export const defaultTextBox: TextItemType = {
    text: '',
    fontSize: 85,
    spacing: 35,
    lineSpace: 42,
    side: 'front',
    position: 'top',
    alignment: 'center',
    color: colors[0].value,
    fontType: Fonts[0].name,
    blendMode: 'normal'
}

type Props2 = {
    notCustomizable?: boolean
}
export default function CustomText(props: Props2) {
    const [context, _] = useCustomImageContext()

    return (
        <div >
            <div className="flex gap-4 items-center mb-2 ">
                <h2 className="text-sm md:text-base font-semibold text-slate-800 dark:text-white">
                    {props.notCustomizable ? "Customize Text" : "Add Text"}
                </h2>
                {!props.notCustomizable && <AddCustomTextBoxButton />}
            </div>
            <div className="flex gap-2 flex-col">
                {
                    context.textBoxes?.map((item, index) => (
                        <TextDropDownItem
                            key={index}
                            index={index}
                            item={item}
                            notCustomizable={props.notCustomizable}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export const AddCustomTextBoxButton = ({ className }: { className?: string }) => {
    const [context, setContext] = useCustomImageContext()
    const { toast } = useToast()

    const handleAddText = () => {
        if (!context.textBoxes || context.textBoxes?.length === 0) {
            setContext(prev => ({
                ...prev,
                textBoxes: [defaultTextBox]
            }))
        }
        else if (context.textBoxes && context.textBoxes?.length < 4) {
            setContext(prev => {
                return {
                    ...prev,
                    // @ts-ignore
                    textBoxes: [...prev.textBoxes, {
                        ...defaultTextBox,
                        // @ts-ignore
                        position: prev.textBoxes[prev.textBoxes.length - 1].position === 'top' ? 'bottom' : 'top'
                    }]
                }
            })
        }
        else if (context.textBoxes?.length === 4)
            toast({
                title: 'Limit Reached',
                description: 'You can only add 4 texts',
                variant: 'destructive'
            })
    }

    return (
        <button
            className="transition-all hover:text-slate-700 text-slate-950 active:text-slate-600"
            onClick={handleAddText}
        >
            <PlusCircle className={cn("size-5", className)} />
        </button>
    )
}

type Props = {
    index: number
    item: TextItemType
    notCustomizable?: boolean
}

const TextDropDownItem = (props: Props) => {
    const [menuOpen, setMenuOpen] = useState(false)


    const closeMenu = () => {
        setMenuOpen(false)
    }
    return (
        <div className='flex items-center justify-center gap-2'>

            <CustomTextBoxInput index={props.index} notCustomizable={props.notCustomizable} />
            <DropdownMenu key={props.index} open={menuOpen} onOpenChange={setMenuOpen}>
                <DropdownMenuTrigger asChild className="block">
                    <Button className='rounded-full !p-0 !py-0 h-fit shadow-sm'>
                        <Settings className={cn('m-1 h-full aspect-square transition-all duration-200', menuOpen && 'rotate-45')} />
                    </Button>

                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-80 z-[152]" align="end" forceMount>
                    <DropdownMenuLabel className="font-normal">
                        <div className="flex flex-col space-y-1">
                            <p className="text-sm leading-none font-bold">Customize</p>
                        </div>
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                        <CustomizeTextBoxOptions index={props.index} item={props.item} />
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}


export const CustomizeTextBoxOptions = (props: Props & { dark?: boolean }) => {
    const [fontSize, setFontSize] = useState<number>(props.item.fontSize)
    const [spacing, setSpacing] = useState<number>(props.item.spacing)
    const [lineSpace, setLineSpace] = useState<number>(props.item.lineSpace)

    const deferredFontSize = useDeferredValue(fontSize)
    const deferredSpacing = useDeferredValue(spacing)
    const deferredLineSpace = useDeferredValue(lineSpace)

    useEffect(() => {

        setCustomImageContext(prev => ({
            ...prev,
            textBoxes: prev.textBoxes?.map((item, i) => i === props.index ? {
                ...item,
                fontSize: deferredFontSize,
                spacing: deferredSpacing,
                lineSpace: deferredLineSpace
            } : item)
        }))

    }, [deferredFontSize,
        deferredSpacing,
        deferredLineSpace,])

    const [customImageContext, setCustomImageContext] = useCustomImageContext()
    const nonUrlCtx = useNonUrlContext()
    const currentBox = customImageContext.textBoxes?.[props.index]
    const changeTextBox = (key: keyof TextItemType, value: any) => {
        setCustomImageContext(prev => ({
            ...prev,
            textBoxes: prev.textBoxes?.map((item, i) => i === props.index ? {
                ...item,
                [key]: value
            } : item)
        }))
    }

    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeTextBox('color', event.target.value)
    };

    const changeFontSize = (n: number[]) => {
        setFontSize(n[0])
    }

    const changeSpacing = (n: number[]) => {
        setSpacing(n[0])
    }

    const changeLineSpace = (n: number[]) => {
        setLineSpace(n[0])
    }

    const setSelectedColor = (color: string) => {
        changeTextBox('color', color)
    }

    const setFontType = (n: string) => {
        changeTextBox('fontType', n)
    }

    const c = "flex flex-row w-full items-center justify-start mb-2"

    if (!currentBox) return null
    return (

        <div className="flex flex-col p-2">
            <div className={cn(c, 'relative h-14 overflow-hidden', nonUrlCtx.selectedColor?.colorType === ColorType.DARK ? 'bg-slate-800' : 'bg-slate-200')}>
                <TextOnlyPreview textBox={{ ...currentBox, fontSize, spacing, lineSpace }} />
            </div>

            <div className={c}>
                <p className="text-xs font-semibold me-2 text-nowrap !w-16">
                    Font Size
                </p>
                <Slider min={10} max={200} onValueChange={changeFontSize} value={[fontSize]} />
                <p className="text-xs pl-1">
                    {fontSize}%
                </p>
            </div>

            <div className={c}>
                <p className="text-xs font-semibold me-2 text-nowrap !w-16">
                    Wrapping
                </p>
                <Slider min={10} max={100} onValueChange={changeSpacing} value={[spacing]} />
                <p className="text-xs pl-1">
                    {spacing}%
                </p>
            </div>

            <div className={c}>
                <p className="text-xs font-semibold me-2 text-nowrap !w-16">
                    Line Space
                </p>
                <Slider min={10} max={100} onValueChange={changeLineSpace} value={[lineSpace]} />
                <p className="text-xs pl-1">
                    {lineSpace}%
                </p>
            </div>

            <div className={c}>
                <p className="text-xs font-semibold me-2 text-nowrap w-12">
                    Side
                </p>
                <ButtonLink
                    name="Front"
                    className="px-2 py-0 text-sm"
                    onClick={() => changeTextBox('side', 'front')}
                    dark
                    selected={currentBox.side === 'front'}
                />
                <ButtonLink
                    name="Back"
                    className="px-2 py-0 text-sm"
                    dark
                    onClick={() => changeTextBox('side', 'back')}
                    selected={currentBox.side === 'back'}
                />
            </div>

            <div className={c}>
                <p className="text-xs font-semibold me-2 text-nowrap w-12">
                    Position
                </p>
                <ButtonLink
                    name="Top"
                    className="px-2 py-0 text-sm"
                    dark
                    onClick={() => changeTextBox('position', 'top')}
                    selected={currentBox.position === 'top'}
                />
                <ButtonLink
                    name="Bottom"
                    className="px-2 py-0 text-sm"
                    dark
                    onClick={() => changeTextBox('position', 'bottom')}
                    selected={currentBox.position === 'bottom'}
                />
            </div>


            <div className={c}>
                <p className="text-xs font-semibold me-2 text-nowrap w-12">
                    Align
                </p>
                <ButtonLink
                    name="Left"
                    className="px-2 py-0 text-sm"
                    onClick={() => changeTextBox('alignment', 'left')}
                    selected={currentBox.alignment === 'left'}
                    dark
                />
                <ButtonLink
                    name="Center"
                    className="px-2 py-0 text-sm"
                    onClick={() => changeTextBox('alignment', 'center')}
                    selected={currentBox.alignment === 'center'}
                    dark
                />
                <ButtonLink
                    name="Right"
                    className="px-2 py-0 text-sm"
                    dark
                    onClick={() => changeTextBox('alignment', 'right')}
                    selected={currentBox.alignment === 'right'}
                />
            </div>


            <div className={c}>
                <p className="text-xs font-semibold me-2 text-nowrap w-12">
                    Color
                </p>
                <div className="flex gap-1 items-center">
                    {colors.map((color) => (
                        <ColorRadio
                            key={color.value}
                            color={color.value}
                            name="color"
                            value={color.value}
                            checked={currentBox.color === color.value}
                            onChange={handleColorChange}
                        />
                    ))}
                    <ColorPicker setColor={setSelectedColor} />
                </div>
            </div>


            <div className={c}>
                <p className="text-xs font-semibold me-2 text-nowrap w-12">
                    Blend
                </p>
                <ButtonLink
                    name="Normal"
                    className="px-2 py-0 text-sm"
                    onClick={() => changeTextBox('blendMode', 'normal')}
                    dark
                    selected={currentBox.blendMode === 'normal'}
                />
                <ButtonLink
                    name="Overlay"
                    className="px-2 py-0 text-sm"
                    onClick={() => changeTextBox('blendMode', 'overlay')}
                    dark
                    selected={currentBox.blendMode === 'overlay'}
                />
            </div>


            <div className={c}>
                <p className="text-xs font-semibold me-2 text-nowrap w-12">
                    Font
                </p>
                <div className={cn("flex gap-1", props.dark && 'dark')}>
                    <Select value={currentBox.fontType} onValueChange={setFontType} >
                        <SelectTrigger className={"w-[180px] h-7 " + currentBox.fontType} >
                            <SelectValue placeholder="Select font" className={"text-xs " + currentBox.fontType} />
                        </SelectTrigger>
                        <SelectContent className="z-[1000]">
                            <SelectGroup>
                                {Fonts.map((font, i) =>
                                    <SelectItem key={i} value={font.name} className={`${font.fontClass} h-7 text-3xl`}>
                                        {font.name}
                                    </SelectItem>
                                )}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </div>

    )
}


export const CustomTextBoxInput = (props: { index: number, className?: string, notCustomizable?: boolean }) => {
    const [customImageContext, setCustomImageContext] = useCustomImageContext()
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCustomImageContext(prev => ({
            ...prev,
            textBoxes: prev.textBoxes?.map((item, i) => i === props.index ? {
                ...item,
                text: e.target.value
            } : item)
        }))
    }

    const removeItem = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (customImageContext.textBoxes && customImageContext.textBoxes?.length > 1)
            setCustomImageContext(prev => ({
                ...prev,
                textBoxes: prev.textBoxes?.filter((item, i) => i !== props.index)
            }))
        else if (customImageContext.textBoxes?.length === 1)
            setCustomImageContext(prev => ({
                ...prev,
                textBoxes: [defaultTextBox]
            }))
    }

    return (
        <div className="flex w-full items-center justify-center">
            <Textarea
                placeholder="Add Text..."
                className={cn("shadow-sm bg-transparent border-slate-300", props.className)}
                onChange={onInputChange}
                value={customImageContext.textBoxes?.[props.index].text}
            />
            {!props.notCustomizable &&
                <button className='rounded-full !p-0 !py-0 h-fit text-red-700' onClick={removeItem}>
                    <Trash2 className={cn('m-1 h-full aspect-square transition-all duration-200')} />
                </button>}
        </div>
    )
}

export { Fonts as TextBoxFonts }
