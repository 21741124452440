import { cn } from '@/lib/utils'
import Link from 'next/link'
import React from 'react'

type props = {
    href?: string
    name: string
    selected?: boolean
    tooltipContent?: string
    onClick?: () => void
    accent?: boolean
    className?: string
    dark?: boolean
}

export default function ButtonLink(props: props) {
    const c = `text-nowrap rounded-full px-4 me-2 py-2 transition duration-300 focus:outline-none ${props.selected
        ? (props.dark ? "bg-ffmain-950 text-ffmain-50 border border-white" : "bg-ffmain-950 text-ffmain-50")
        : (props.dark ? "bg-ffmain-50/50 text-ffmain-950" : "bg-ffmain-50/50 text-ffmain-900 hover:bg-ffmain-100/60 border border-ffmain-900")
        }`

    const className = cn(c, props.className)

    if (props.href)
        return (
            <Link
                href={props.href}
                replace={true}
                scroll={false}
                className={className}
                data-tooltip-content={props.tooltipContent || props.name}
                data-tooltip-id='tooltip'
                aria-label={props.tooltipContent || props.name}
            >
                {props.name}
            </Link>
        )

    else
        return (
            <button
                className={className}
                data-tooltip-content={props.tooltipContent || props.name}
                data-tooltip-id='tooltip'
                onClick={props.onClick}
                aria-label={props.tooltipContent || props.name}
            >
                {props.name}
            </button>
        )
}
