import { Plus } from 'lucide-react';
import React, { useDeferredValue, useEffect } from 'react';

type Props = {
    setColor: (color: string) => void;
}
const ColorPicker = (props: Props) => {
    const [color, setColor] = React.useState<string | null>()
    const deferredColor = useDeferredValue(color)

    useEffect(() => {
        if (deferredColor)
            props.setColor(deferredColor)
    }, [deferredColor])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setColor(e.target.value)
    }
    return (
        <div className='relative rounded-full overflow-hidden size-6'>
            <input
                type="color"
                className='size-full bg-transparent'
                onChange={handleInputChange}
            />
            <div
                className='absolute size-full top-0 left-0 cursor-pointer w-6 h-6 flex items-center justify-center pointer-events-none bg-gradient-to-br from-yellow-500 via-orange-500 to-indigo-500'>
                <Plus className='text-gray-200 size-full bg-black/20' />
            </div>

        </div>
    );
};

export default ColorPicker
