"use client";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { useDeferredValue, useEffect } from "react";
import { ImageGenActions, useGeneratedImages } from "./GeneratedImagesProvider";
import { ColorType } from "@/utils/APIRouteTypes";
import { CustomImageContextType } from "@/types/contextTypes";

const CustomImageContext = React.createContext<
  | [
    CustomImageContextType,
    React.Dispatch<React.SetStateAction<CustomImageContextType>>,
  ]
  | undefined
>(undefined);

export const CustomImageContextProvider = ({
  children,
  defaultProps,
  isStatic
}: {
  children: React.ReactNode;
  defaultProps: CustomImageContextType;
  isStatic?: boolean;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const path = usePathname()
  const [generatedImages, setGeneratedImages, _] = useGeneratedImages()
  const [imageContext, setImageContext] =
    React.useState<CustomImageContextType>(defaultProps);

  const deferredContext = useDeferredValue(imageContext);

  useEffect(() => {
    const images = new Set(imageContext.frontImages.concat(imageContext.backImages).map((image) => image.src))
    const generatedImagesSrc = new Set(generatedImages.map((image) => image.src))

    const difference = images.difference(generatedImagesSrc)
    setGeneratedImages({ type: ImageGenActions.ADD_IMAGE, payload: Array.from(difference).map((src) => ({ src, color: ColorType.LIGHT })) })
  }, [])

  useEffect(() => {
    if (!isStatic) {
      //@ts-ignore
      const newParams = new URLSearchParams(searchParams);
      newParams.set("context", encodeURIComponent(JSON.stringify(deferredContext)));
      newParams.delete("image");
      router.replace(path + "?" + newParams.toString(), {
        scroll: false,

      });
    }

  }, [deferredContext]);

  return (
    <CustomImageContext.Provider value={[imageContext, setImageContext]}>
      {children}
    </CustomImageContext.Provider>
  );
};

export const useCustomImageContext = () => {
  const context = React.useContext(CustomImageContext);
  if (context === undefined) {
    throw new Error(
      "useCustomImageContext must be used within a CustomImageContextProvider",
    );
  }
  return context;
};
