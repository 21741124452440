import { useNonUrlContext } from '@/contexts/NonUrlContext'
import { TextItemType } from '@/types/contextTypes'
import { BoundingBoxFromAPI } from '@/utils/APIRouteTypes'
import { Rubik_Font } from '@/utils/fonts'
import React, { useEffect, useState } from 'react'
import { TextBoxFonts } from '../detailComponents/customText'

type Props2 = {
    textBox: TextItemType
    width?: number
}

export const TextOnlyPreview = (props: Props2) => {
    const ref = React.useRef<HTMLParagraphElement>(null)
    const [paragraphWidth, setparagraphWidth] = React.useState(50)
    const nonURLContext = useNonUrlContext()
    const f = TextBoxFonts.find(f => f.name === props.textBox.fontType)
    let st: React.CSSProperties = {}
    if (props.textBox.blendMode === 'overlay' && (nonURLContext.selectedColor?.name.toLowerCase() === 'white' || nonURLContext.selectedColor?.name.toLowerCase() === 'black'))
        st = {
            opacity: 0.35,
            mixBlendMode: 'normal',
        }
    useEffect(() => {
        if (ref.current && ref.current.parentElement) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    if (entry.target === ref.current?.parentElement) {
                        setparagraphWidth(entry.contentRect.width); // Update the width state
                    }
                }
            });

            resizeObserver.observe(ref.current?.parentElement); // Start observing the parent element

            return () => {
                resizeObserver.disconnect(); // Cleanup on unmount
            };
        }
    }, [])


    return (
        <p
            className={"text-sm me-2 text-nowrap flex absolute w-full items-start !py-0 !pt-0 !pb-0 overflow-hidden transition-all " + f?.fontClass}
            style={{
                fontSize: `${paragraphWidth / 10}px`,
                letterSpacing: `${(props.textBox.spacing / 70) - 0.5}em`,
                lineHeight: `${(props.textBox.lineSpace / 40) - 0.15}em`,
                color: props.textBox.color,
                justifyContent: props.textBox.alignment,
                textAlign: props.textBox.alignment,
                top: props.textBox.position === 'top' ? 0 : 'auto',
                bottom: props.textBox.position === 'bottom' ? 0 : 'auto',
                whiteSpace: 'pre-wrap',
                // WebkitTextStroke: '2px red',
                mixBlendMode: props.textBox.blendMode,
                ...st,
                scale: props.textBox.fontSize / 50,
                transformOrigin: props.textBox.position,
            }}
            ref={ref}
        >
            {props.textBox.text.split('\n').map((line, index) => (
                <>
                    {line}
                    <br />
                </>))
            }
        </p>
    )
}
