import { CustomImageContextType } from "@/types/contextTypes";

type ListResponse<T> = {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

export enum ListingRequestStatus {
    PENDING = "Pending",
    ACCEPTED = "Accepted",
    REJECTED = "Rejected"
}

export enum PredictionStatus {
    PENDING = 'Pending',
    SUCCESS = 'Success',
    FAILED = 'Failed'
}

export type PredictionObject = {
    id: number
    status: PredictionStatus
    output: PredictionImageType[]
    isKnownCharacter: boolean
    created: string
}

export type PredictionImageType = {
    image: string | null
}

export type UploadedImageType = {
    image: string
}

export type ProductList = ListResponse<ProductListItem>

export enum PaymentObjectStatus {
    PENDING = 'Pending',
    SUCCESS = 'Success',
    FAILED = 'Failed',
    COD = 'COD'
}

export enum PaymentShippingStatus {
    DISPATCHING = "Dispatching",
    SHIPPING = "Shipping",
    DELIVERED = "Delivered",
}

export type PaymentObject = {
    created_ts: string
    price: number
    rzp_id: string
    cartItems: CartItemType[]
    status: PaymentObjectStatus
    shipping_status: PaymentShippingStatus | null
    tracking_url: string | null
}


export type ProductListItem = {
    slug: string;
    name: string;
    price: number;
    image: string | null
    category: ProductItemCategory;
    colors: ColorListItemFromAPI[];
    initialSize: string
    url: string;
    itemsAvailable: number;
    MRP: number;
    material: string
    initialColor: string
    blurDataURL: string | null
}

export type NCProductListItem = {
    slug: string;
    name: string;
    price: number;
    category: ProductItemCategory
    designOnFront: boolean
    colors: ColorListItemFromAPI[];
    url: string
    image: string
    MRP: number
    itemsAvailable: number
    material: string
    initialSize: SizeFromAPI
    initialColor: ColorFromAPI
    blurDataURL: string | null
}

export type ProductDetail = {
    id: number
    slug: string;
    imgWidth: number | null
    imgHeight: number | null
    colors: ColorFromAPI[];
    sizes: SizeFromAPI[];
    boundingBoxFront: BoundingBoxFromAPI;
    boundingBoxBack: BoundingBoxFromAPI | null
    MRP: number;
    itemsAvailable: number;
    name: string;
    material: string;
    image: string;
    description: string;
    price: number;
    category: ProductItemCategory;
    additionalDetails: string[];
    sizeChart: string | null
    metaTitle: string | null
    metaDescription: string | null
}

export type NCProductDetailType = {
    id: number
    name: string
    slug: string
    description: string
    price: number
    MRP: number
    itemsAvailable: number
    material: string
    colors: ColorFromAPI[]
    sizes: SizeFromAPI[]
    category: ProductItemCategory
    additionalDetails: string[]
    sizeChart: string
    metaTitle: string
    designOnFront: boolean
    metaDescription: string
    imgWidth: number | null
    imgHeight: number | null
}

export type BoundingBoxFromAPI = {
    id: number;
    x: number;
    y: number;
    width: number;
    height: number;
};

export enum ColorType {
    LIGHT = 'Light',
    DARK = 'Dark',
    BRIGHT = 'Bright'
}

export type ColorListItemFromAPI = {
    name: string;
    hexCode: string;
}

export type ColorFromAPI = {
    id: number;
    images: ImageFromAPI[];
    sizes: SizeFromAPI[];
    name: string;
    hexCode: string;
    colorType: ColorType;
    isAvailable: boolean;
};

export type SampleImageResponse = ListResponse<SampleImage>

export type SampleImage = {
    id: number;
    image: string;
    category: ProductItemCategory;
}

export type ImageFromAPI = {
    id: number;
    image: string;
    altText: string;
    side: ImageSide
};

export enum ImageSide {
    FRONT = "Front",
    BACK = "Back",
    LEFT = "Left",
    RIGHT = "Right",
}

export enum ProductItemCategory {
    MEN = "Men",
    WOMEN = "Women",
    BOYS = "Boys",
    GIRLS = "Girls",
    ACCESSORIES = 'Accessories'
}

export type SizeFromAPI = {
    id: number;
    name: string;
    abbreviation: string;
    cameraBox: BoundingBoxFromAPI | null
    isCircular: boolean
    priority: number
};

export type CatalogItem = {
    imageWithTShirt: string
    prompt: string
    designSrc: string
    color: string
    height: number
    width: number
    bgMode: ColorType
}

export type CatalogItemList = ListResponse<CatalogItem>

export type MyDesignObjectType = {
    output: PredictionImageType[]
    prompt: string
    bgMode: ColorType
}

type WLBase = {
    colorsExist: boolean
    imgHeight: number
    imgWidth: number
    selectedColor: ColorFromAPI | null
    selectedSize: SizeFromAPI | null

}

export type CustomizableWishListItem = WLBase & {
    productDetails: ProductListItem
    context: CustomImageContextType
    productID: number
    nc_productDetails: null
    nc_productID: null
    boundingBoxFront: BoundingBoxFromAPI
    boundingBoxBack: BoundingBoxFromAPI | null
}

export type NCWishListItem = WLBase & {
    productDetails: null
    context: null
    productID: null
    nc_productDetails: NCProductListItem
    nc_productID: number
    boundingBoxFront: null
    boundingBoxBack: null
}

export type WishListItem = CustomizableWishListItem | NCWishListItem


export type MyDesignListType = ListResponse<MyDesignObjectType>
export type WishListType = ListResponse<WishListItem>
export type CartItemType = WishListItem & {
    quantity: number
    listingRequest: ListingRequestStatus | null
    id: number
}

export type CartItemUpdateType = {
    quantity: number
}


export type CartListType = ListResponse<CartItemType>

export type OrderListType = ListResponse<PaymentObject>
export type OrderDetailType = PaymentObject & {
    id: number
    user: string
    name: string
    email: string
    phone: string
    address: string
    city: string
    state: string
    pincode: string
    ipAddress: string
}


export type MyListingItemType = {
    id: number
    status: ListingRequestStatus
    err_msg: string | null
    checkoutItem: CartItemType
    created_ts: string
}

export type MyListingListType = ListResponse<MyListingItemType>

export type NCProductListType = ListResponse<NCProductListItem> & {
    collection_description?: string | null
    collection_name?: string | null
}

export type TemplateListType = ListResponse<{
    product: ProductListItem
    image: string
    context: CustomImageContextType
    slug: string
    url: string
}>


export type TemplateDetailType = {
    product: ProductDetail
    image: string
    context: CustomImageContextType
    slug: string
    url: string
}
