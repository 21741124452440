// src/TShirt.js
'use client'
import Image from "next/image";

type TShirtProps = {
  src: string;
  altText: string
  height: number
  width: number
  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
};

const TShirt = (props: TShirtProps) => {

  return (
    <Image src={props.src}
      alt={props.altText}
      className="object-contain w-full"
      priority={true}
      loading="eager"
      height={props.height}
      data-src={props.src}
      width={props.width}
      onClick={props.onClick}
      onContextMenu={(e) => e.preventDefault()}
    />
  );
};

export default TShirt;
