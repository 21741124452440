'use client'
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useCommonContext } from '@/contexts/CommonContext';
import { X } from "lucide-react";
import { signIn } from 'next-auth/react';
import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';
import { FaDiscord, FaGoogle } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { RiTwitterXLine } from "react-icons/ri";
import { Checkbox } from "../ui/checkbox";

type Props = {
    ua: string
}

export default function LoginModal(props: Props) {
    const [commonContext, setCommonContext] = useCommonContext()
    const [open, setOpen] = useState(false)
    const [agreedToTerms, setAgreedToTerms] = useState(true)

    useEffect(() => {
        setOpen(commonContext.loginModalOpen)
    }, [commonContext.loginModalOpen])

    const closeModal = () => {
        setCommonContext({
            ...commonContext,
            loginModalOpen: false
        })
        setOpen(false)
    }

    const handleAgreeToTerms = () => {
        setAgreedToTerms(prev => !prev)
    }

    useEffect(() => {
        if (open) {
            if (!agreedToTerms)
                setAgreedToTerms(true)
        }
    }, [open])

    const socialButtons = [
        { icon: FaGoogle, name: 'Google', provider: 'google', className: 'bg-white text-slate-800 border border-slate-300 hover:bg-slate-100 shadow-sm' },
        { icon: FaFacebook, name: 'Facebook', provider: 'facebook', className: 'bg-blue-600 text-white hover:bg-blue-600 hover:text-slate-100' },
        { icon: RiTwitterXLine, name: 'X / Twitter', provider: 'twitter', className: 'bg-black text-white hover:bg-slate-800 hover:text-slate-100' },
        { icon: FaDiscord, name: 'Discord', provider: 'discord', className: 'bg-indigo-600 text-white hover:bg-indigo-600 hover:bg-indigo-700 hover:text-white' },
    ]

    const modalText = useMemo(() => {
        if (commonContext.loginModalAction === 'imageGen')
            return "Sign in to generate designs"
        else if (commonContext.loginModalAction === 'cod')
            return "Sign in to place a cash on delivery order"
        else if (commonContext.loginModalAction === 'login')
            return "Sign in to our platform"

    }, [commonContext.loginModalAction])

    const skipGoogle = props.ua.includes("Facebook") || props.ua.includes("Instagram") || props.ua.includes("Snapchat")

    return (
        <Dialog open={open} onOpenChange={closeModal} >
            <DialogContent className=" sm:max-w-[425px] bg-gradient-to-br from-red-50 to-orange-100 dark:from-slate-900 dark:to-slate-800 p-0 overflow-hidden">
                <div className="p-6 relative z-10">
                    <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-ffmain-500 via-ffaccent-500 to-red-500" />
                    <Button
                        className="absolute top-2 right-2 h-8 w-8 rounded-full p-0"
                        variant="ghost"
                        onClick={closeModal}
                    >
                        <X className="h-4 w-4 text-ffmain-800" />
                        <span className="sr-only">Close</span>
                    </Button>
                    <h2 className="text-2xl font-bold text-center mb-2">
                        Welcome
                    </h2>
                    <p className="text-center text-sm text-muted-foreground mb-6 text-ffmain-900">
                        {modalText}
                    </p>
                    <div className="space-y-3">
                        {socialButtons.filter(x => {
                            if (x.name === 'Google' && skipGoogle)
                                return false
                            return true
                        }).map((button) => (
                            <Button
                                key={button.name}
                                variant="outline"
                                className={`w-full h-12 flex items-center justify-center space-x-2 ${button.className}`}
                                onClick={() => signIn(button.provider)}
                                id={"Login Button - " + button.name}
                                disabled={!agreedToTerms}
                            >
                                {button.name === 'Google' ? (
                                    <>
                                        <svg viewBox="0 0 24 24" className="h-5 w-5" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                                                <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z" />
                                                <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z" />
                                                <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z" />
                                                <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z" />
                                            </g>
                                        </svg>
                                        <span className="font-medium">Sign in with Google</span>
                                    </>
                                ) : (
                                    <>
                                        <button.icon className="h-5 w-5" />
                                        <span className="font-medium">Sign in with {button.name}</span>
                                    </>
                                )}
                            </Button>
                        ))}
                    </div>
                    <div className="flex mt-6 items-center justify-center">
                        <Checkbox className="size-6 me-1" checked={agreedToTerms} onCheckedChange={handleAgreeToTerms} />
                        <p className="text-center text-xs text-muted-foreground ">
                            I agree to <Link href={'/tnc'} className='text-blue-500'>Terms of Service</Link>, <Link href={'/privacy-policy'} className='text-blue-500'>Privacy Policy </Link> and receiving promotional emails from FlashyFox.
                        </p>
                    </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-primary/10 to-transparent" />
                <svg
                    className="absolute bottom-0 left-0 right-0 text-orange-200"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                >
                    <path
                        fill="currentColor"
                        fillOpacity="1"
                        d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                    ></path>
                </svg>
            </DialogContent>
        </Dialog >
    )
}
