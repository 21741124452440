import React from 'react';

interface ColorRadioProps {
    color: string;
    name: string;
    value: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ColorRadio: React.FC<ColorRadioProps> = ({ color, name, value, checked, onChange }) => {
    return (
        <label className="relative inline-block cursor-pointer">
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                className="sr-only" // Hide the default radio button
            />
            <div
                className="size-6 rounded-full relative overflow-hidden ring-1 ring-gray-300"
                style={{ backgroundColor: color }}
            >
                <div
                    className={`absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out ${checked ? 'opacity-100' : 'opacity-0 scale-0'
                        }`}
                >
                    <div className="w-1/2 h-1/2 bg-white rounded-full border"></div>
                </div>
            </div>
        </label>
    );
};

export default ColorRadio;
